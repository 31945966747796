<template>
  <div class="home">
    <div id="page-hero" class="pt-6 pb-2 pt-md-12 mb-0 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="8">
            <h1 class="display-2 mb-4"><i style="font-weight:normal;">Connected</i><br/>Corporate Fitness</h1>
            <br/>
            <p v-if="false">
              Use {{tenant.name}} to create engaging connected corporate fitness events! Our auto-sync allows your employees to get and stay fit and healthy whilst using their favorite fitness trackers.
            </p>
            <h3 class="headline">
              <!-- Create connected corporate fitness events to get more happy employees. Happy and fit employees are more productive, less stressed, and reduce healthcare costs! -->
              Fit and happy employees are more productive and less stressed.<br/>
              Why wait? Let's Go!
            </h3>
            <p class="mb-0 mt-8">
            </p>
          </v-col>
          <v-col v-if="false" cols="12" sm="4">
            <v-icon v-if="false" color="white" size="100">fa-watch</v-icon>
            <img src="/images/logo-cofi.png" width="300" style="max-width:100%;"/>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col class="pb-0">
          <h3 class="title">Inclusive and engaging</h3>
          <p>
            Offering a connected fitness challenge is a great way to organize a corporate wellness program which is both fun and engaging at the same time. 
            Our platform allows you to host inclusive challenges which are motivating all of your workforce, not only the most competitive few!
          </p>
          <p>
            Our leaderboards can be fully customized and show the real-time progress of your challenges. 
            The social result feeds allow your employees to cheer each other up  by giving them likes and comments. 
          </p>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/get">
                <v-icon size="50" color="grey">fa-mobile</v-icon>
                <h3 class="my-4">iPhone and Android App</h3>
                <p>Download our free iPhone or Android app to track your progress.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/fitness">
                <v-icon size="50" color="grey">fa-watch-fitness</v-icon>
                <h3 class="my-4">9+ Fitness Device Integrations</h3>
                <p>Connect any of our many supported devices for auto-sync from your app or watch.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4">
                <v-icon size="50" color="grey">fa-keyboard</v-icon>
                <h3 class="my-4">Manual Entry</h3>
                <p>Easily enter activities and steps on our website.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/microsoft-teams">
                <img height="50px" src="https://sodisp.imgix.net/web/microsoft-teams-logo-sm.png?h=50" class="mb-n2" style="filter:grayscale(1);"/>
                <h3 class="my-4">Microsoft Teams</h3>
                <p>Integrate into Microsoft Teams channels and chats for a seamless connected Teams experience.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/slack">
                <v-icon size="50" color="grey">fab fa-slack</v-icon>
                <h3 class="my-4">Slack Integration</h3>
                <p>Integrate into your Slack workspace for a seamless and integrated experience for your team.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/solutions/fitness-engine">
                <v-icon size="50" color="grey">fa-globe</v-icon>
                <h3 class="my-4">Globally Available</h3>
                <p>Connect all your employees, regardless of their country, language, or time zone.</p>
              </v-card>
            </v-col>
            <!--
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/zapier">
                <v-icon size="50" color="grey">fa-asterisk</v-icon>
                <h3 class="my-4">Zapier Integration</h3>
                <p>Integrate into your Slack workspace for a seamless and integrated experience for your team.</p>
              </v-card>
            </v-col>
            -->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    </div>
 
    <div class="green white--text py-6 py-md-12 text-center">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Get Started Today!</h2>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" href="/eventmanager/create">Create Your Event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/request-demo">Schedule a Meeting</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>


     <div id="" class="mt-0 grey lighten-4 ">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate dark/>
          <p class="mb-0 mt-4 text-center">
            Over <strong>1,087 events</strong> have been created already. Join today!
          </p>
          <p class="mb-0 mt-4 text-center">
            <v-btn class="mr-4 mb-4" href="/eventmanager/create">Create Your Event</v-btn>
            <v-btn class="mb-4"  outlined href="/contact">Contact Us</v-btn>
          </p>

          </v-col>
        </v-row>
      </div>
    </div>

    
  </div>
  
</template>

<script>
import axios from "axios";
import EventGrid from "@/components/EventGrid.vue";
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    EventGrid,
    SectionIntegrations,
    SectionCustomers,
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
    };
  },
  async mounted() {

  }, 
};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 15px; margin-top: 15px; }
    .plus {
      margin: 0 20px; line-height: 30px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }

  /* CLS improvements:  */
  #page-hero { min-height: 358px;}
  #page-customers { min-height: 340px;}
  #page-integrations { min-height: 436px;}
  .cta-bar { min-height: 332px;}

  @media (max-width: 500px) {
    /*#page-hero { min-height: 750px;}*/
    #page-customers { min-height: 560px;}
    #page-integrations { min-height: 625px;}
    .cta-bar { min-height: 332px;}
  }</style> 